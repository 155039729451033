<div (window:click)="windowClick($event)">
  <ngx-ui-loader [loaderId]="'core-loader'"></ngx-ui-loader>
  <div id="mainContent" class="mainContent">
    <router-outlet></router-outlet>
  </div>
</div>

<!--<div><mat-card>-->
<!--  <mat-card-content>-->
<!--    <ul>-->
<!--      <li *ngFor="let file of files">-->
<!--        <mat-progress-bar [value]="file.progress"></mat-progress-bar>-->
<!--        <span id="file-label">-->

<!--                    </span>-->
<!--      </li>-->
<!--    </ul>-->
<!--  </mat-card-content>-->
<!--  <mat-card-actions>-->
<!--    <button mat-button color="warn" (click)="onClick()">-->
<!--      <mat-icon>file_upload</mat-icon>-->
<!--      Upload-->
<!--    </button>-->
<!--  </mat-card-actions>-->
<!--</mat-card><input type="file" #fileInput id="fileUpload" name="fileUpload" multiple="multiple" accept="image/*" style="display:none;" /></div>-->
