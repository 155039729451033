import { Component, OnInit } from '@angular/core';
import {MsgService} from "@app/services/msg/msg";
import { NgxUiLoaderService } from 'ngx-ui-loader';

declare let $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {

  constructor(private msg: MsgService, private loader: NgxUiLoaderService){}

  ngOnInit() {
    //this.loader.startLoader('core-loader');
    this.resize();
  }

  resize() {
    document.getElementById("mainContent").style.height = window.innerHeight + 'px';
  }


  windowClick(evt) {
    if (document.getElementsByTagName('snack-bar-container').length > 0) {
      let snackBar = document.getElementsByTagName('snack-bar-container')[0];
      if ($(snackBar).has($(evt.target)).length == 0 && !$(snackBar).is($(evt.target)))
        this.msg.hide();
    }
  }

}
