import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {isNullOrUndefined} from "util";
import {MsgService} from "../msg/msg";
import {environment} from "@env/environment";

@Injectable({
  providedIn: 'root'
})
export class AjaxService {
  private headers = new HttpHeaders().append('Content-Type', 'application/json').append('Cache-control', 'no-cache');
  constructor(private http: HttpClient, private msg: MsgService) {}

  public static getServerUrl() {
    return `${environment.root}:${environment.port}${environment.url}`;
  }

  exec(url: string): Observable<any> {
    return this.http.post(AjaxService.getServerUrl() + url, {}, {headers: this.headers});
  }

  post(data: any, url: string, opts?: any) {
    let options: { [k: string]: any };
    options = (opts ? opts : {});
    options.headers = this.headers;
    return this.http.post<any>(AjaxService.getServerUrl() + url, {data}, options);
  }

  async data(data: any, url: string, opts?: any) {
    let options: { [k: string]: any };
    options = (opts ? opts : {});
    options.headers = this.headers;
    let response = await this.http.post<any>(AjaxService.getServerUrl() + url, {data}, options)
      .toPromise()
      .catch(error => {
        if(error.status == 404) {
          this.msg.err('Bad request URL: ' + url);
        }
      });
    if(!response.success) this.msg.err(data.msg);
    return response;
  }

  postFile(data: FormData, url: string): Observable<any> {
    return this.http.post<any>(AjaxService.getServerUrl() + url, data, {
      reportProgress: true,
      observe: 'events'
    });
  }

  getFile(data: any, url: string): Observable<any> {
    return this.http.post(AjaxService.getServerUrl() + url, {data}, {
      reportProgress: true,
      responseType: 'blob'
    })
  }

  nvl(key: any, replacement?: any) {
    if (isNullOrUndefined(key) || key.length == 0) {
      return replacement ? replacement : null;
    } else
      return key;
  }

}
