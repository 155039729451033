import {Injectable} from '@angular/core';
import {isNullOrUndefined} from 'util';
import {Title} from '@angular/platform-browser';
import {TranslateService} from '@ngx-translate/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class  Globals {
  constructor(
    private titleService: Title,
    private ui: TranslateService,
  ) {
  }

  public port: string = '';
  public static appName: string = 'DL';
  private name = new BehaviorSubject('');

  public setFormId(id) {
    localStorage.setItem('form-id', id);
  }

  public getTheme() {
    return this.name.asObservable();
  }

  public setTheme(name) {
    this.name.next(name);
  }

  public getFormId() {
    return localStorage.getItem('form-id');
  }

  public setUrl(url?) {
    localStorage.setItem('last-url', url ? url : window.location.hash.replace('#', ''));
  }

  public getUrl() {
    return localStorage.getItem('last-url');
  }

  public setForms(count) {
    localStorage.setItem('forms', count);
  }

  public getForms() {
    try {
      return parseInt(localStorage.getItem('forms'));
    } catch (e) {
      return 0;
    }
  }

  public setContractId(id) {
    localStorage.setItem('contract-id', id);
  }

  public getContractId() {
    let id = parseInt(localStorage.getItem('contract-id'));
    return isNaN(id) ? 0 : id;
  }

  public setTranshId(id) {
    localStorage.setItem('transh-id', id);
  }

  public getTranshId() {
    let id = parseInt(localStorage.getItem('transh-id'));
    return isNaN(id) ? 0 : id;
  }

  public setClaimId(id) {
    localStorage.setItem('claim-id', id);
  }

  public getClaimId() {
    let id = parseInt(localStorage.getItem('claim-id'));
    return isNaN(id) ? 0 : id;
  }

  public setPagetitle() {
    this.titleService.setTitle(`${localStorage.getItem('page-title')}`);
  }

  public setTitle(name) {
    localStorage.setItem('page-title', this.ui.instant(name) + ' - ' + this.getAppName());
  }

  public getAppName() {
    return Globals.appName;
  }

  public nvl(key: any, replacement?: any) {
    if (isNullOrUndefined(key) || key.length == 0) {
      return replacement ? replacement : null;
    } else
      return key;
  }

  public unMaskSum(value) {
    let cleanValue = value.replace(/\s/g, '');
    return cleanValue.replace(',', '') / 100;
  }

  getRoot(level?) {
    return window.location.hash.replace('#/', '').split('/')[level || 0];
  }

  back(levels?: number) {
    let urlArray = this.getUrl().split('/');
    return urlArray.splice(0, urlArray.length - levels || 1).join('/');
  }

}

