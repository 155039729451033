import {BrowserModule, HAMMER_GESTURE_CONFIG, HammerModule, Title} from '@angular/platform-browser';
import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {DEFAULT_PERFECT_SCROLLBAR_CONFIG, HttpLoaderFactory, LoaderProperties} from '@app/configs/common.configs';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {MaterialModule} from './material';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {CoreSnackBar} from "./services/msg/snack";
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {NgxUiLoaderModule} from 'ngx-ui-loader';
import {NgxMaskModule} from 'ngx-mask'
import {GestureConfig, MatTabNav} from '@angular/material';
import {PERFECT_SCROLLBAR_CONFIG} from 'ngx-perfect-scrollbar';
import {TokenService} from "@app/services/core/token.service";
import {FormsModule} from '@angular/forms';
import {registerLocaleData} from '@angular/common';
import localeRu from '@angular/common/locales/ru';
import {GlobalHttpInterceptorService} from "@app/services/global-http-Interceptor.service";

registerLocaleData(localeRu);

@NgModule({
  declarations: [
    AppComponent,
    CoreSnackBar
  ],
  imports: [
    BrowserModule,
    MaterialModule,
    FormsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    NgxUiLoaderModule.forRoot(LoaderProperties),
    NgxMaskModule.forRoot(),
    TranslateModule.forRoot({loader: {provide: TranslateLoader, useFactory: (HttpLoaderFactory), deps: [HttpClient]}}),
    HammerModule
  ],
  providers: [
    Title,
    MatTabNav,
    {provide: HAMMER_GESTURE_CONFIG, useClass: GestureConfig},
    {provide: HTTP_INTERCEPTORS, useClass: GlobalHttpInterceptorService, multi: true},


    {provide: PERFECT_SCROLLBAR_CONFIG, useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG},
    {provide: CUSTOM_ELEMENTS_SCHEMA, useValue: CUSTOM_ELEMENTS_SCHEMA},
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
