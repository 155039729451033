import { NgModule } from '@angular/core';
import {
  MatDialogModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatTabsModule,
  MatSlideToggleModule,
  MatSnackBarModule,
  MatSidenavModule,
  MatListModule,
  MatGridListModule,
  MatExpansionModule,
  MatCardModule,
  MatSelectModule,
  MatChipsModule,
  MatTooltipModule,
  MatCheckboxModule,
  MatFormFieldModule,
  MatInputModule,
  MatSortModule,
  MatButtonModule,
  MatToolbarModule,
  MatIconModule,
  MatMenuModule,
  MatTableModule,
  MatProgressSpinnerModule,
  MatPaginatorModule,
  MatTreeModule,
  MatBadgeModule,
  MatRadioModule,
  MatProgressBarModule,
} from "@angular/material";


@NgModule({
  imports: [MatProgressBarModule, MatBadgeModule, MatChipsModule, MatDialogModule, MatDatepickerModule, MatNativeDateModule, MatTabsModule, MatSlideToggleModule, MatSnackBarModule, MatSidenavModule, MatIconModule, MatListModule, MatGridListModule, MatExpansionModule, MatCardModule, MatSelectModule, MatTooltipModule, MatButtonModule, MatCheckboxModule, MatToolbarModule, MatIconModule, MatMenuModule, MatTableModule, MatSortModule, MatFormFieldModule, MatInputModule, MatProgressSpinnerModule, MatPaginatorModule, MatTreeModule, MatRadioModule],
  exports: [MatProgressBarModule, MatBadgeModule, MatChipsModule, MatDialogModule, MatDatepickerModule, MatNativeDateModule, MatTabsModule, MatSlideToggleModule, MatSnackBarModule, MatSidenavModule, MatIconModule, MatListModule, MatGridListModule, MatExpansionModule, MatCardModule, MatSelectModule, MatTooltipModule, MatButtonModule, MatCheckboxModule, MatToolbarModule, MatIconModule, MatMenuModule, MatTableModule, MatSortModule, MatFormFieldModule, MatInputModule, MatProgressSpinnerModule, MatPaginatorModule, MatTreeModule, MatRadioModule],
})

export class MaterialModule {
}
