import {Injectable} from '@angular/core';
import {CanActivate, Router} from '@angular/router';

@Injectable({providedIn: 'root'})
export class DoctorGuard implements CanActivate {

  isDoctor: boolean = true;

  constructor(private router: Router) {}

  canActivate() {
    if (+localStorage.getItem("role_id") != 6) {
      this.router.navigateByUrl('/orders/list', {skipLocationChange: true}).then(() =>
        this.router.navigate(['/orders/list']));
    }
    return this.isDoctor;
  }

}
