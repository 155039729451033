import {Injectable} from "@angular/core";
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from "@angular/common/http";
import {Router} from "@angular/router";
import {Observable, of, throwError} from "rxjs";
import {catchError, tap} from "rxjs/operators";
import {TranslateService} from "@ngx-translate/core";
import {MsgService} from "@app/services/msg/msg";
import {AuthService} from "@app/services/core/auth.service";

@Injectable()
export class GlobalHttpInterceptorService implements HttpInterceptor {

  constructor(public router: Router,
              private msg: MsgService,
              private auth: AuthService,
              private translate: TranslateService) {
  }



  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next.handle(req.clone({headers: req.headers.set('Authorization', 'Bearer ' + this.auth.isAuthed())})).pipe(
      catchError((error) => {
        console.log(error)
        let handled: boolean = false;
        console.error(error);
        if (error instanceof HttpErrorResponse) {
          if (error.error instanceof ErrorEvent) {
            console.error("Error Event");
          } else {
            console.log(`error status : ${error.status} ${error.statusText}`);
            switch (error.status) {
              // case 401:      //login
              //   // this.token.signOut();
              //   // this.router.navigateByUrl("/auth");
              //   handled = true;
              //   break;
              // case 403:     //forbidden
              //   // this.token.signOut();
              //   // this.router.navigateByUrl("/auth");
              //   console.log(`redirect to login`);
              //   handled = true;
              //   break;
              // case 404:     //forbidden
              //   // this.token.signOut();
              //   // this.router.navigateByUrl("/auth");
              //   console.log(`404`);
              //   handled = true;
              //   break;
              case 0:     //forbidden
                // this.token.signOut();
                // this.router.navigateByUrl("/auth");
                console.log(`500`);
                handled = true;
                this.msg.err(`${this.translate.instant('Ошибка запроса. *Примечание: нажмите комбинацию CTRL+SHIFT+R для обновления запроса')}`);
                break;
            }
          }
        }
        else {
          console.error("Other Errors");
        }

          // this.msg.err(`${this.translate.instant('Ошибка запроса')}`);

        // this.toastrService.success();
        return throwError(error);
      })
    )
  }
}
