import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {AjaxService} from "./ajax.service";
import { BehaviorSubject } from 'rxjs';
import {Globals} from "@app/configs/globals";

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public authed: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(
    private global: Globals,
    private route: Router,
    private http: AjaxService) {}

  logout() {
    let locale = '';
    this.http.exec('out').subscribe(data => {});
    this.global.setTitle('auth');
    this.authed.next(false);
    this.global.setPagetitle();
    locale = localStorage.getItem('lang');
    localStorage.clear();
    localStorage.setItem('lang', locale);
    this.route.navigate(['/auth']);
    window.sessionStorage.clear();
  }

  isAuthed() {
    return localStorage.getItem('token')
  }

}
