import {PerfectScrollbarConfigInterface} from 'ngx-perfect-scrollbar';
import {HttpClient} from '@angular/common/http';
import {MultiTranslateHttpLoader} from 'ngx-translate-multi-http-loader';
import {POSITION} from 'ngx-ui-loader';

export const LoaderProperties = {
  bgsPosition: POSITION.centerCenter,
  bgsSize: 1,
  fgsSize: 1,
  bgsOpacity: 1,
  logoPosition: POSITION.centerCenter,
  threshold : 5,
  logoSize:120
};

export const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {suppressScrollX: true};


export function HttpLoaderFactory(httpClient: HttpClient) {
  return new MultiTranslateHttpLoader(httpClient, [
    {prefix: "./assets/locale/core/", suffix: ".json"},
  ]);
}
