import {Injectable, isDevMode} from '@angular/core';
import {isNullOrUndefined} from "util";
import {CoreSnackBar} from "./snack";
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material/snack-bar';

declare let $: any;

@Injectable({
  providedIn: 'root'})
export class MsgService {

  constructor(private msb: MatSnackBar, private ui: TranslateService) {}

  success(msg: any): void {
    let message: string = '';
    if (msg instanceof Array) {
      for (let i = 0; i < msg.length; i++) {
        message += this.ui.instant(msg[i]) + '<br/>';
      }
    } else if (msg instanceof Object)
      message = JSON.stringify(msg);
    else
      message = msg.toString();
    let ang = this;
    setTimeout(function(){
      ang.openMsg(message, false);
    }, 200);
  }

  err(msg: any): void {
    let message: string = '';
    if (msg instanceof Array) {
      for (let i = 0; i < msg.length; i++) {
        if (msg[i] instanceof Array) {
          for (let j = 0; j < msg[i].length; j++) {
            if (isDevMode()) {
              message += this.ui.instant(msg[i][j]) + '<br/>';
            } else {
              if (msg[i][j].indexOf('DEV:') == -1)
                message += this.ui.instant(msg[i][j]) + '<br/>';
            }
          }
          message += '<br/>'
        } else {
          if (isDevMode()) {
            message += this.ui.instant(msg[i]) + '<br/>';
          } else {
            if (msg[i].indexOf('DEV:') == -1)
              message += this.ui.instant(msg[i]) + '<br/>';
          }
        }
      }
    } else if (msg instanceof Object)
      message = JSON.stringify(msg);
    else
      message = msg.toString();
    //
    let ang = this;
    setTimeout(function() {
      ang.openMsg(message, true);
    }, 200);
  }

  private openMsg(message, isError) {
    this.msb.openFromComponent(CoreSnackBar, {
      data: this.ui.instant(message),
      verticalPosition: "top",
      duration: 300000,
      panelClass: [isError ? 'error-snackbar' : 'success-snackbar']
    });
  }

  isNull(val) {
    try {
      return val + '' == '' || val == null || isNullOrUndefined(val);
    } catch (e) {
      return false;
    }
  }

  checkFields(isCheck) {
    let doms = $('.form-content input,.form-content select, .form-content textarea'), isFirst = true;
    for (let i = 0; i < doms.length; i++) {
      let elem = $(doms[i]);
      if (elem.is(':required')) {
        if (isCheck) {
          if (elem.val() != '' && elem.val() != null) {
            elem.css('border', "1px solid #ced4da").css('box-shadow', "none");
          } else {
            elem.css('border', "1px solid red").css('box-shadow', "0 0 2px 1px red");
            if (isFirst) elem.focus();
            isFirst = false;
          }
        }
        elem.change(function () {
          if (elem.val() != '') {
            elem.css('border', "1px solid #ced4da").css('box-shadow', "none");
          } else {
            elem.css('border', "1px solid red").css('box-shadow', "0 0 2px 1px red");
          }
        });
      }
    }
  }

  public checkMatFields(isCheck) {
    let doms = $('.form-content mat-form-field input[matInput],form-content mat-form-field mat-select'), isFirst = true;
    for (let i = 0; i < doms.length; i++) {
      let elem = $(doms[i]);
      if (elem.is(':required')) {
        if (isCheck) {
          if (elem.val() != '' && elem.val() != null) {
            elem.css('border', "none").css('box-shadow', "none");
          } else {
            elem.css('border', "none").css('box-shadow', "none");
            if (isFirst) elem.focus();
            isFirst = false;
          }
        }
      }
    }
  }

  hide() {
    this.msb.dismiss();
  }

  checkMsg(data: any) {
    if (data.success)
      this.success(data.msg);
    else
      this.err(data.msg);
  }
}
