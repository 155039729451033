import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {AuthGuard} from "@app/services/core/auth.guard";
import {EmpMainGuard} from "@app/configs/emp-main.guard";
import {DoctorGuard} from "@app/configs/doctor.guard";
import {AdminGuard} from "@app/configs/admin-guard";

const routes: Routes = [
  {path: '', canActivate: [AuthGuard], loadChildren: () => import('@app/modules/core/core-main.module').then(m => m.CoreMainModule)},
  {path: 'auth',                       loadChildren: () => import('@app/modules/core/core-auth.module').then(m => m.CoreAuthModule)},
  {path: 'confirm',                    loadChildren: () => import('@app/modules/core/core-confirm.module').then(m => m.CoreConfirmModule)},
  {path: 'password-recovery',          loadChildren: () => import('@app/modules/core/core-pwd-recovery.module').then(m => m.CorePwdRecoveryModule)},
  {path: 'register',                   loadChildren: () => import('@app/modules/core/core-register.module').then(m => m.CoreRegisterModule)},
  {path: 'orders',    canActivate: [AuthGuard], loadChildren: () => import('@app/modules/mk/cs-orders.module').then(m => m.CsOrdersModule)},
  {path: 'new-ord',   canActivate: [DoctorGuard],  loadChildren: () => import('@app/modules/mk/mk-new-order.module').then(m => m.MkNewOrderModule)},
  {path: 'profile',   loadChildren: () => import('@app/modules/mk/mk-profile.module').then(m => m.MkProfileModule)},
  {path: 'users',     canActivate: [AdminGuard], loadChildren: () => import('@app/modules/mk/cs-users.module').then(m => m.CsUsersModule)},
  {path: 'work-panel',canActivate:[EmpMainGuard], loadChildren: () => import('@app/modules/mk/cs-wp.module').then(m => m.CsWpModule)},
  {path: '**', redirectTo: 'orders', pathMatch: 'full'},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true, onSameUrlNavigation: 'reload'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
